$(document).on('turbolinks:load', function () {
    const adminUrl = $('#admin_url').val();

    $('#btn-new-admin').click(function () {
        $('#admin-form').trigger("reset");
        $('#admin-modal-title').html('New Account');
        reset_error_text();
        $('.message-handling').html('');
    });

    $('#btn-change-password').click(function () {
        $('#changePasswordForm').trigger("reset");
        reset_error_text();
        $('.message-handling').html('');
    });

    $('#changePasswordForm')
        .on('ajax:success', function (data) {
            let data_return = data.detail[0];
            if (data_return.status) {
                alert('Success ! You need to re-login');
                location.reload();
            } else {
                reset_error_text();
                let error_message = data_return.error.error_messages[0];
                if (error_message.includes('confirmation')) {
                    set_error_text($('#error-password-confirmation'), error_message);
                } else {
                    set_error_text($('#error-password'), error_message);
                }
            }
        }).on('ajax:error', function () {
        messageHandle.serverErrorHandle();
    });

    function reset_error_text() {
        $('.input-error').html('');
    }

    function set_error_text(input, message) {
        input.html(message)
    }

    $('.btn-edit-admin').click(function () {
        setDataFormEdit($(this).data('url'));
        reset_error_text();
        $('#admin-modal-title').html('Edit Account');
        $('#admin-save-btn').data('url', $(this).data('url'));
        $('.message-handling').html('');
    });

    $('#admin-save-btn').click(function (event) {
        event.preventDefault();
        let data = $('#admin-form').serialize();
        let id = $('#id').val();
        if (id.length === 0) {
            createAccount(data)
        } else {
            updateAccount(data, id)
        }
    });

    function setDataFormEdit(url) {
        $.ajax({
            type: "GET",
            url: url,
            success: function (data) {
                if (data.status) {
                    ['id', 'email', 'status', 'role'].forEach(function (item) {
                        $(`#${item}`).val(eval(`data.data.${item}`));
                    });
                }
            }
        });
    }

    function createAccount(data) {
        $.ajax({
            type: "POST",
            url: adminUrl,
            data: data,
            success: function (data) {
                successAdminHandle(data);
            },
            error: function () {
                messageHandle.serverErrorHandle();
            }
        })
    }

    function updateAccount(data, id) {
        $.ajax({
            type: "PUT",
            url: `${adminUrl}/${id}`,
            data: data,
            success: function (data) {
                successAdminHandle(data);
            },
            error: function () {
                messageHandle.serverErrorHandle();
            }
        })
    }

    function successAdminHandle(data) {
        if (data.status) {
            location.reload();
            messageHandle.successHandle();
        } else {
            set_error_text($('#input-error-email'), data.error.error_messages[0]);
        }
    }
});
