export class Cursor {
    constructor(baseRef, pageSize) {
        this.baseRef = baseRef;
        this.referenceToOldestKey = null;
        this.pageSize = pageSize;
        this.stop = false;
    }

    next() {
        if (this.stop) return Promise.resolve([]);

        let ref = this.baseRef;

        if (this.referenceToOldestKey == null) {
            ref = ref.orderByKey().limitToLast(this.pageSize);
        } else {
            ref = ref.orderByKey().endAt(this.referenceToOldestKey).limitToLast(this.pageSize + 1);
        }
        return ref.once('value')
            .then(snapshot => {
                if (!snapshot.val()) return Promise.resolve([]);
                let arrayOfKeys;
                if (this.referenceToOldestKey == null) {
                    arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                } else {
                    arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                }
                let values = arrayOfKeys.map((key) => snapshot.val()[key]);
                this.referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                if (arrayOfKeys.length == 0)
                    this.stop = true;
                let result = [];
                for (let i = 0; i < arrayOfKeys.length; i++) {
                    result.push({
                        'key': arrayOfKeys[i],
                        'value': values[i]
                    });
                }
                return result;
            });
    }
}