$(document).on('turbolinks:load', function () {

    // variable
    const COUNTRY_CODE_AND_CURRENCY = {
        '+62': 'Rp',
        '+84': 'VNĐ',
        '+81': 'JPY'
    };
    const fromDateElement = $('#datePickerFromDate');
    const maxMoneyDiscountRowElement = $('#max_money_discount_row');
    const maxMoneyDiscountElement = $('#coupon_max_money_discount');
    const percentCheckboxElement = $('#coupon_discount_type_percent');
    const moneyCheckboxElement = $('#coupon_discount_type_money');
    const couponDiscountElement = $('#coupon_discount');
    // validation variable
    const untilDateElement = $('#datePickerUntilDate');
    const timeLimitElement = $('#coupon_time_limit');
    const itemElement = $('#coupon_item');
    const itemLimitElement = $('#coupon_item_limit');
    // country variable
    const countryElement = $('#coupon_country_code');


    // set datetime picker
    initFlatpickr();

    function flatpickrOptions(defaultDate) {
        return {
            enableTime: true,
            dateFormat: 'Z',
            altFormat: 'F j, Y H:i:S',
            altInput: true,
            defaultDate: defaultDate
        }
    }
    function initFlatpickr() {
        const defaultDate = fromDateElement.val() === "" ? '' : new Date(fromDateElement.val());
        fromDateElement.flatpickr(flatpickrOptions(defaultDate));
    }

    // end set datetime picker

    // set validation

    timeLimitChangeValidation();
    timeLimitElement.change(() => timeLimitChangeValidation());

    itemLimitChangeValidation();
    itemLimitElement.change(() => itemLimitChangeValidation());

    function timeLimitChangeValidation() {
        if (timeLimitElement.is(":checked")) {
            untilDateElement.flatpickr().destroy();
        } else {
            const defaultDate = untilDateElement.val() === "" ? '' : new Date(untilDateElement.val());
            untilDateElement.flatpickr(flatpickrOptions(defaultDate))
        }
        untilDateElement.attr('readonly', 'readonly');
    }

    function itemLimitChangeValidation() {
        if (itemLimitElement.is(":checked")) {
            itemElement.attr('readonly', 'readonly');
            itemElement.val('');
        } else {
            itemElement.removeAttr('readonly');
        }
    }

    // end validation

    //set currency by country
    currencyProcess();
    countryElement.change(function () {
        currencyProcess();
    });

    function currencyProcess() {
        let currencyLabel = $('#discount_type_money');
        let currency = COUNTRY_CODE_AND_CURRENCY[countryElement.val()];
        currencyLabel.text(currency);
    }

    // end set currency by country

    // max money discount display
    toggleMaxMoneyDiscount();
    percentCheckboxElement.click(() => toggleMaxMoneyDiscount());
    moneyCheckboxElement.click(() => toggleMaxMoneyDiscount());

    function toggleMaxMoneyDiscount() {
        if (percentCheckboxElement.is(":checked")) {
            maxMoneyDiscountRowElement.removeClass('d-none');
            couponDiscountElement.attr('placeholder', 'percent from 0 to 1');
        } else {
            maxMoneyDiscountRowElement.addClass('d-none');
            maxMoneyDiscountElement.val('');
            couponDiscountElement.attr('placeholder', 'money discount');
        }
    }
});