import firebase from './firebase_initialize';
import 'firebase/database';

const database = firebase.database();
const CHAT_ROOT_NODE = 'chatrooms';
const CLIENT_ROOT_NODE = 'Client';
const WORKER_ROOT_NODE = 'Worker';
const WORKER_TYPE = 'Worker';

function workerRoomDetailRef(id, worker) {
    return database.ref(`${WORKER_ROOT_NODE}/${worker.accountId}/rooms/${id}`);
}
function clientRoomDetailRef(id, client) {
    return database.ref(`${CLIENT_ROOT_NODE}/${client.accountId}/rooms/${id}`);
}
function updateRoomInfo(id, member, message) {
    const worker = member.worker;
    const client = member.client;
    const payload = {
        lastMessageTime: message.postdate,
        lastMessageText: message.message,
        lastMessageKey: message.key
    };
    updateClientRoomInfo(id, client, payload);
    updateWorkerRoomInfo(id, worker, payload);
}

function updateClientRoomInfo(id, client, payload) {
    return clientRoomDetailRef(id, client).update(payload);
}

function updateWorkerRoomInfo(id, worker, payload) {
    return workerRoomDetailRef(id, worker).update(payload);
}
function getLastMessage(id) {
    return databaseRef(id).orderByKey().limitToLast(1);
}

function getLastMessageValue(id) {
    return getLastMessage(id).once('value');
}

function pushMessage(payload, id) {
    return databaseRef(id).push(payload);
}

function deleteMessage(id, key) {
    return database.ref(`${CHAT_ROOT_NODE}/${id}/${key}`).remove();
}

function updateMessage(id, key, data) {
    return database.ref(`${CHAT_ROOT_NODE}/${id}/${key}`).update(data)
}

function databaseRef(id) {
    return database.ref(`${CHAT_ROOT_NODE}/${id}`)
}

function firebaseTimestamp() {
    return database.repo_.serverTime();
}

function updateLastMessageRead(id, object, key) {
    const payload = {lastMessageRead: key};
    if(object.accountType === WORKER_TYPE){
        return updateWorkerRoomInfo(id, object, payload);
    }else{
        return updateClientRoomInfo(id, object, payload);
    }
}

export {
    firebaseTimestamp,
    getLastMessage,
    getLastMessageValue,
    pushMessage,
    deleteMessage,
    updateMessage,
    databaseRef,
    updateRoomInfo,
    updateLastMessageRead
}