$(function () {
    // $("#worker_profile_attributes_skill_id").bsMultiSelect();
    $("#worker_languages").bsMultiSelect();
    $(".worker-video").on('change', function (e) {
        let FileSize = this.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 10) {
            alert('File size exceeds 10 MB');
            $(this).val(null);
        }
    });

    function readURL(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#profile-avatar-showing').attr('src', e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    $(".attributes_avatar").change(function () {
        readURL(this);
    });

    if ($("#edit-worker-skills")) {
        const btnAddSkill = "#edit-worker-skills #btn-add-skill"
        const btnRemoveSkill = "#edit-worker-skills .btn-remove-skill"

        $(btnRemoveSkill).click(function () {
            $(this).parent().parent().remove()
        })

        $(btnAddSkill).click(function () {
            let newElement = $(".worker_skill[hidden]").clone().removeAttr("hidden")
            $(newElement)
            .insertBefore(btnAddSkill)
            .find($(btnRemoveSkill))
            .click(function () {
                $(this).parent().parent().remove()
            })
        })
    }
});
