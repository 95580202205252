$(document).on('turbolinks:load', function () {
    let block_url = '/admins/block_ips';
    $(".new-ip").click(function() {
        $(".text-ip").removeClass('d-none');
        $('.text-ip').html('<input type="text" name="ip" id="ip" placeholder="IP" class="col-sm-8 form-control"/>');
        $(".new-ip").addClass('d-none');
        $(".add-ip").removeClass('d-none');
    });
    $(".add-ip").click(function() {
        let ip = $('#ip').val();
        $.ajax({
            url: block_url,
            method: "POST",
            data: {ip: ip},
            dataType: "json",
            success: function (ip) {
                location.reload();
            }
        });
    });
});
