$(document).on('turbolinks:load', function () {
    const CLIENT_TYPE = 'Client';
    
    config_flatpickr_for_time();
    worker_status_by_operator();

    function config_flatpickr_for_time() {
        let time = $('#timeDatetimePicker').val();
        let default_time = time === '' ? new Date() : time;
        flatpickr('#timeDatetimePicker', {
            enableTime: true,
            dateFormat: "Y-m-d H:i:S",
            defaultDate: default_time,
            minDate: new Date()
        });
    }

    $('#firebase_push_schedule_account_type').change(function () {
        worker_status_by_operator();
    });

    function worker_status_by_operator() {
        let client_type_value = $('#firebase_push_schedule_account_type').val();
        let worker_status_group = $('#firebase_push_schedule_worker_status').closest('.form-group');
        if (client_type_value == CLIENT_TYPE) {
            worker_status_group.addClass('d-none')
        } else {
            worker_status_group.removeClass('d-none')
        }
    }
});
