import {
    firebaseTimestamp,
    deleteMessage,
    databaseRef,
    getLastMessageValue,
    getLastMessage,
    pushMessage,
    updateMessage,
    updateLastMessageRead,
    updateRoomInfo
} from "./firebase_database_services";
import {Cursor} from "./firebase_database_pagination";
import {deleteStorage, uploadStorage, getDownloadUrl} from "./firebase_storage_services";

$(document).on('turbolinks:load', function () {
    const pageSize = 10;
    const stringCompactSize = 20;
    const adminType = 'Admin';
    const clientType = 'Client';
    const workerType = 'Worker';
    const videoSupportType = ['video/mp4'];
    const fileSizeLimit = 20; //MB
    const applicantStatusApply = 'applied';
    let cursor;
    let applicant_id = '';
    let applicant_ids = [];
    setTriggerForInputMessage();
    triggerForMoreSetting();
    $('.last-message').each(function () {
        let applicant_id = $(this).data('applicant-id');
        let applicant_status = $(this).data('applicant-status');
        setApplicantId(applicant_id);
        setApplicantStatus(applicant_status);
        applicant_ids.push(applicant_id);
        const htmlMessage = $(this);
        getLastMessage(getApplicantId()).on('child_added', function (data) {
            setLastMessageHtml(htmlMessage, data.val())
        });
        getLastMessage(getApplicantId()).on('child_changed', function (data) {
            setLastMessageHtml(htmlMessage, data.val())
        });
        childRemoveTrigger(getApplicantId());
        childAddTrigger(getApplicantId());
        childChangeTrigger(getApplicantId());
    });

    function childRemoveTrigger(id) {
        databaseRef(id).on('child_removed', function (data) {
            $(`#${data.key}`).remove();
            let dataValue = data.val();
            if (dataValue.attachmentFileName) {
                deleteStorage(id, dataValue.attachmentFileName);
            }
        });
    }

    function childAddTrigger(id) {
        databaseRef(id).on('child_added', function (data) {
            newMessageShowing(data.val(), data.key);
        })
    }

    function childChangeTrigger(id) {
        databaseRef(id).on('child_changed', function (data) {
            $(`#${data.key}`).find('.messageText').text(data.val().message);
        })
    }

    // Show last message
    lastMessagesShowing();

    function lastMessagesShowing() {
        let actions = applicant_ids.map(getLastMessageValue);
        let resultPromise = Promise.all(actions);
        let result = [];
        resultPromise.then(data => {
            data.map(function (item) {
                let data = (item.val() == null) ? new Object() : item.val();
                result.push(Object.values(data));
            });

            let index = 0;
            applicant_ids.map(function (item) {
                let data = result[index];
                if (data.length !== 0) {
                    data = data[0];
                    let htmlMessage = $(`#last-message-${item}`);
                    let message = getLastMessageText(data);
                    htmlMessage.text(stringCompact(message, stringCompactSize));
                    $(`#date-${item}`).html(dateTimeFormat(data.postdate));
                }
                index += 1;
            });
        });
    }

    // List message when click to worker
    $('.worker-chat-item').click(function (e) {
        e.preventDefault();
        $('.worker-chat-item').removeClass('active');
        clearMessage();
        $(this).addClass('active');
        $('#load-more-message').html('');
        setLoginAsOptionDefault();
        setWorkerAvatarUrl($(this).find('img').attr('src'));
        setWorkerAvatar();
        let dataElement = $(this).find('.last-message');
        setApplicantId(dataElement.data('applicant-id'));
        setWorkerId(dataElement.data('worker-id'));
        handleMessageShowingById(getApplicantId());
    });

    $('#load-more-message').click(function (e) {
        e.preventDefault();
        showMessage();
    });

    $('#job-detail-switch').click(function (e) {
        e.preventDefault();
        $('.job-detail-container').removeClass('d-none');
        $('.chat-box-container').addClass('d-none');
        $(this).addClass('d-none');
        $('.worker-chat-item').removeClass('active');
    });

    $("[name='loginAsOption']").change(function () {
        if (getAccountType() !== adminType) {
            clearMessage();
            handleMessageShowingById(getApplicantId());
        }
    });

    $('#btn-send-message').click(async function (e) {
        e.preventDefault();
        let inputMessage = $('#input-message-send');
        let messageValue = inputMessage.val().trim();
        if (!messageValue) {
            inputMessage.addClass('border-danger')
        } else {
            let accountType = getAccountType();
            let accountId = getAccountId(accountType);
            let payload = {
                accountId: accountId,
                accountType: accountType,
                attachment: '',
                attachmentContentType: '',
                attachmentFileName: '',
                message: messageValue,
                postdate: firebaseTimestamp()
            };
            await sendMessage(payload);
            clearInputMessage();
        }
        focusToChatInput();
    });

    async function sendMessage(payload){
        let applicantId = getApplicantId();
        let accountType = getAccountType();
        let accountId = getAccountId(accountType);
        let object = {accountId: accountId, accountType: accountType};
        let clientMember = {accountId: getAccountId(clientType), accountType: clientType };
        let workerMember = {accountId: getAccountId(workerType), accountType: workerType };
        let member = {client: clientMember, worker: workerMember};
        let messagePush = await pushMessage(payload, applicantId);
        payload['key'] = messagePush.key
        // updateRoomInfo
        // change message to Attachment File if file upload for update to room information
        payload.message = getLastMessageText(payload);
        updateRoomInfo(applicantId, member, payload);
        // update read message to object(client/worker)
        if (object.accountType === adminType) return;
        updateLastMessageRead(applicantId, object, messagePush.key);
    }

    $('#input-message-send').click(function () {
        $(this).removeClass('border-danger')
    });

    $('#attachmentFile').change(function () {
        let fileData = (this.files && this.files[0]) ? this.files[0] : false;
        if (fileData) {
            let fileSize = (fileData.size / 1024 / 1024).toFixed(2);
            if (fileSize >= fileSizeLimit) {
                alert('File size > 20MB');
            } else {
                uploadFile(fileData);
            }
        }
        $(this).val('');
    });

    function uploadFile(file) {
        $('#modalLoading').modal('show');
        let accountTypeConst = getAccountType();
        let accountIdConst = getAccountId(accountTypeConst);
        let fileName = (new Date).getTime() + file.name;
        let contentType = file.type;
        let applicantId = getApplicantId();
        uploadStorage(applicantId, fileName, file)
            .on('state_changed', function (snapshot) {
            }, function () {
                $('#modalLoading').modal('hide');
                alert('Error Upload File');
            }, function () {
                getDownloadUrl(applicantId, fileName)
                    .then(async (downloadUrl) => {
                        let payload = {
                            accountId: accountIdConst,
                            accountType: accountTypeConst,
                            attachment: downloadUrl,
                            attachmentContentType: contentType,
                            attachmentFileName: fileName,
                            message: '',
                            postdate: firebaseTimestamp()
                        };
                         await sendMessage(payload);
                         $('#modalLoading').modal('hide');
                    })
            })
    }

    function moveScrollToBottomChatBox() {
        $('#chat-box')[0].scrollIntoView(false);
    }

    function handleMessageShowingById(id) {
        cursor = new Cursor(databaseRef(id), pageSize);
        showMessage();
    }

    function newMessageShowing(data, key) {
        showingMessageToHtml(data, key, 'append');
        moveScrollToBottomChatBox();
    }

    function showMessage() {
        let isFirstLoad = (cursor.referenceToOldestKey == null);

        cursor.next().then((data) => {
            data.forEach(function (item) {
                showingMessageToHtml(item.value, item.key, 'prepend');
            });
            if (data.length < pageSize)
                $('#load-more-message').html('');
            else
                $('#load-more-message').html('load more');
            if (isFirstLoad) moveScrollToBottomChatBox();
        })
    }

    function showingMessageToHtml(data, key, functionName) {
        let accountTypeChose = getDefaultAccountTypeChose();
        let receiverAvatarUrl = (accountTypeChose == clientType) ? getWorkerAvatarUrl() : getClientAvatarUrl();
        if (data.accountType == adminType) {
            eval(`$('#chat-box').${functionName}(adminMessage(data, key))`);
            setAdminAvatar();
        } else if (data.accountType == accountTypeChose) {
            // sender message
            eval(`$('#chat-box').${functionName}(senderMessage(data, key))`);
        } else {
            // receiver message
            eval(`$('#chat-box').${functionName}(receiverMessage(data, key))`);
        }
        setReceiverAvatar(receiverAvatarUrl);
        showAttachmentFile(data);
    }

    function clearMessage() {
        $('#chat-box').html('');
        $('.job-detail-container').addClass('d-none');
        $('.chat-box-container').removeClass('d-none');
        $('#job-detail-switch').removeClass('d-none');
        clearInputMessage();
    }

    function clearInputMessage() {
        let inputMessage = $('#input-message-send');
        inputMessage.removeClass('border-danger');
        inputMessage.val('');
    }

    function receiverMessage(data, key) {
        let message = htmlEncode(getMessageText(data));
        let date = dateTimeFormat(data.postdate);
        return `        <div class=\"media w-50 mb-3\" id="${key}"><img class=\"rounded-circle imgReceiver chat-thumbnail-img\">\n` +
            "           <div class='col-12'><div class='row'><div class='col-11 m-0 p-0'>" +
            "          <div class=\"media-body ml-3\">\n" +
            "            <div class=\"bg-light contentArea rounded py-2 px-3 mb-2\">\n" +
            `              <p class=\"text-small text-left mb-0 text-muted messageText \">${message}</p>\n` +
            `              <img id=\"img-${data.postdate}\" class='attachment-file'>\n` +
            `               <video id=\"video-${data.postdate}\" class='attachment-file d-none' controls></video>\n` +
            "            </div>\n" +
            `            <p class=\"small text-muted\">${date}</p>\n` +
            "           </div> </div>\n" +
            "           <div class='col-1 m-0 p-0 d-flex align-items-center'>\n" +
            moreSettingForMessage(data, key) +
            "           </div></div> \n" +
            "          </div>\n" +
            "        </div>";
    }

    function senderMessage(data, key) {
        let message = htmlEncode(getMessageText(data));
        let date = dateTimeFormat(data.postdate);
        let seenHtml = "<div class=\"col-1 m-0 p-0 d-flex align-items-center text-center\">\n" +
            "<i class=\"fas fa-check-double text-primary mx-auto mb-5\"></i>\n" +
            "</div>";
        let senderHtml = `<div class=\"media w-50 ml-auto mb-3\" id="${key}">\n` +
            "           <div class=\"col-12\">\n" +
            "           <div class=\"row\">\n" +
            "           <div class=\"col-1 m-0 p-0 d-flex align-items-center\">\n" +
            moreSettingForMessage(data, key) +
            "                    </div>" +
            "             <div class=\"col-10 m-0 p-0\">\n" +
            "          <div class=\"media-body\">\n" +
            "            <div class=\"bg-primary contentArea rounded py-2 px-3 mb-2\">\n" +
            `              <p class=\"text-small mb-0 text-white messageText \">${message}</p>\n` +
            `              <img id=\"img-${data.postdate}\" class='attachment-file'>\n` +
            `               <video id=\"video-${data.postdate}\" controls class='d-none attachment-file'></video>\n` +
            "            </div>\n" +
            `            <p class=\"small text-muted\">${date}</p>\n` +
            "          </div>\n" +
            "          </div>\n";
        if (data.is_seen) senderHtml += seenHtml;
        return senderHtml + "</div> </div>";
    }

    window.eventBus = new function () {
        this.reviewEmit = function (id) {
            let linkElement = `<a id="sth" data-toggle="modal" data-target="#review-modal" data-remote="true" href="/admins/reviews/${id}"></a>`;
            $('body').append(linkElement);
            let temp = $('#sth');
            temp[0].click();
            temp.remove();
        };
        this.clientEmit = function (event, id) {
            event.preventDefault();
            window.location.pathname = `/admins/clients/${id}`;
        };
        this.workerEmit = function (event, id) {
            event.preventDefault();
            window.location.pathname = `/admins/workers/${id}`;
        }
    };

    function adminMessage(data, key) {
        let message = getMessageText(data);
        let date = dateTimeFormat(data.postdate);
        return `        <div class=\"media w-50 mb-3\" id="${key}"><img class=\"rounded-circle imgAdmin chat-thumbnail-img\">\n` +
            "           <div class='col-12'><div class='row'><div class='col-11 m-0 p-0'>" +
            "          <div class=\"media-body ml-3\">\n" +
            "            <div class=\"bg-light contentArea rounded py-2 px-3 mb-2\">\n" +
            `              <p class=\"text-small mb-0 text-left text-muted messageText \">${message}</p>\n` +
            `              <img id=\"img-${data.postdate}\" class='attachment-file'>\n` +
            `               <video id=\"video-${data.postdate}\" class='attachment-file d-none' controls></video>\n` +
            `               <a id=\"data-${data.postdate}\" target='_blank' class='d-none'> Download </a>\n` +
            "            </div>\n" +
            `            <p class=\"small text-muted\">${date}</p>\n` +
            "           </div> </div>\n" +
            "           <div class='col-1 m-0 p-0 d-flex align-items-center'>\n" +
            moreSettingForMessage(data, key) +
            "            </div>\n" +
            "           </div></div> \n" +
            "        </div>";
    }

    function formEditMessage() {
        return "<div class='formEditMessage'>" +
            `<textarea class="form-control formEditMessage"></textarea>` +
            "<button class='btn btn-success m-1 btnSaveEditText'> Save </button>" +
            "<button class='btn btn-danger m-1 btnCancelEditText'> Cancel</button>" +
            "</div>";
    }

    function moreSettingForMessage(data, key) {
        let moreHtml = "<i class=\"fas fa-ellipsis-h mx-auto mb-5\" data-toggle=\"dropdown\" aria-expanded=\"false\"></i>\n" +
            `<div class=\"dropdown-menu moreSettingParent\" data-key="${key}" aria-labelledby=\"dropdownMenuButton\" \n>`;
        let editHTml = "<a class=\"p-2 editMoreSettingOption\" href=\"#\">Edit</a>";
        let deleteHtml = `<a class=\"p-2 d-block deleteMoreSettingOption\" href="#">Delete</a>`;
        let downloadHtml = `<a class=\"p-2 \" href="${data.attachment}" target=\"_blank\">Download</a>`;
        let endHtml = "</div>";
        if (!data.attachmentFileName) {
            moreHtml += editHTml;
        } else {
            moreHtml += downloadHtml;
        }
        return moreHtml + deleteHtml + endHtml;
    }

    function triggerForMoreSetting() {
        $(document).on('click', '.deleteMoreSettingOption', function (e) {
            e.preventDefault();
            deleteMessage(getApplicantId(), $(this).closest('.moreSettingParent').data('key'));
        });
        $(document).on('click', '.editMoreSettingOption', function (e) {
            e.preventDefault();
            let parentElement = $(this).closest('.media');
            let contentAreaElement = parentElement.find('.contentArea');
            contentAreaElement.append(formEditMessage());
            let textareaEdit = parentElement.find('.formEditMessage').find('textarea');
            let messageTextElement = contentAreaElement.find('.messageText');
            textareaEdit.val(messageTextElement.html());
            messageTextElement.addClass('d-none');
            $(this).addClass('d-none');
        });
        $(document).on('click', '.btnCancelEditText', function (e) {
            e.preventDefault();
            let parentElement = $(this).closest('.media');
            let contentAreaElement = parentElement.find('.contentArea');
            let messageTextElement = contentAreaElement.find('.messageText');

            messageTextElement.removeClass('d-none');
            parentElement.find('.formEditMessage').remove();
            parentElement.find('.editMoreSettingOption').removeClass('d-none');
        });
        $(document).on('click', '.btnSaveEditText', function (e) {
            e.preventDefault();
            let parentElement = $(this).closest('.media');
            let textareaEdit = parentElement.find('.formEditMessage').find('textarea');
            let btnCancel = parentElement.find('.btnCancelEditText');

            if (textareaEdit.val() === '') {
                textareaEdit.addClass('border-danger');
            } else {
                let value = textareaEdit.val();
                let firebaseKey = parentElement.find('.moreSettingParent').data('key');
                updateMessage(getApplicantId(), firebaseKey, {message: value})
                    .then(btnCancel.click())
            }
        });
    }

    function showAttachmentFile(data) {
        let attachmentType = data.attachmentContentType;
        let postdate = data.postdate;
        let attachment = data.attachment;
        if (!attachmentType) return;

        if (attachmentType.includes('image')) {
            $(`#img-${postdate}`).attr('src', attachment);
        } else if (videoSupportType.includes(attachmentType)) {
            let videoElement = $(`#video-${postdate}`);
            videoElement.attr('src', attachment);
            videoElement.removeClass('d-none');
        }
    }

    function stringCompact(string, n) {
        return (string.length > n) ? `${string.substr(0, n - 1)} ...` : string;
    }

    function dateTimeFormat(timestamp) {
        return (new Date(timestamp)).toLocaleString();
    }

    function setLoginAsOptionDefault() {
        $("input[name=loginAsOption][value='Admin']").prop('checked', true)
    }

    function setWorkerAvatarUrl(imgUrl) {
        $('#imgWorkerUrl').attr('src', imgUrl);
    }

    function setWorkerAvatar() {
        $('#imgWorker').attr('src', getWorkerAvatarUrl());
    }

    function setAdminAvatar() {
        $('.imgAdmin').attr('src', $('#imgAdminUrl').attr('src'));
    }

    function setReceiverAvatar(imgUrl) {
        $('.imgReceiver').attr('src', imgUrl)
    }

    function setWorkerId(id) {
        $('#workerId').val(id);
    }

    function setApplicantId(id) {
        applicant_id = id;
        let href_hired_default = $('#hired_job').attr('href').split('?')[0];
        $('#hired_job').attr('href', href_hired_default + '?id=' + id);
    }

    function setApplicantStatus(status) {
        if(status != applicantStatusApply){
            $('#hired_job').hide();
        }else{
            $('#hired_job').show();
        }
    }

    function setTriggerForInputMessage() {
        $('#input-message-send').keyup(function (e) {
            // 13 is Enter Key Code
            if (e.keyCode == 13) {
                $('#btn-send-message').click();
            }
        });
    }

    function getApplicantId() {
        return applicant_id;
    }

    function getWorkerAvatarUrl() {
        return $('#imgWorkerUrl').attr('src');
    }

    function getWorkerId() {
        return $('#workerId').val();
    }

    function getClientId() {
        return $('#clientId').val();
    }

    function getAdminId() {
        return $('#adminId').val();
    }

    function getAccountId(accountType) {
        let accountId = '';
        switch (accountType) {
            case adminType:
                accountId = getAdminId();
                break;
            case clientType:
                accountId = getClientId();
                break;
            case workerType:
                accountId = getWorkerId();
                break;
        }
        return parseInt(accountId);
    }

    function getAccountType() {
        return $("[name='loginAsOption']:checked").val();
    }

    function getClientAvatarUrl() {
        return $('#clientAvatarUrl').attr('src');
    }

    function getDefaultAccountTypeChose() {
        return (getAccountType() == adminType) ? clientType : getAccountType();
    }

    function getMessageText(data) {
        let resultMessage = '';
        if (data.attachmentContentType) {
            if (!videoSupportType.includes(data.attachmentContentType) && !data.attachmentContentType.includes('image')){
                resultMessage = 'Attachment File: ' + data.attachmentFileName;
            }
            else { resultMessage = ''; }
        } else {
            resultMessage = data.message;
        }
        return data.isDelete ? resultMessage + ' (is deleted)' : resultMessage
    }

    function getLastMessageText(data) {
        return (!data.attachmentFileName) ? data.message : 'Attachment File';
    }

    function setLastMessageHtml(htmlElement, data) {
        let message = getLastMessageText(data);
        htmlElement.text(stringCompact(message, stringCompactSize));
        $(`#date-${getApplicantId()}`).html(dateTimeFormat(data.postdate));
    }

    function focusToChatInput() {
        $('#btn-send-message').focus();
        $('#input-message-send').focus();
    }

    function htmlEncode(string) {
        return String(string)
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;');
    }
});
