export function successHandle() {
    let successHtml = "<div class=\"alert alert-success alert-dismissible fade show\" role=\"alert\">\n" +
        "  <strong>Success!</strong>\n" +
        "  <button class=\"close\" type=\"button\" data-dismiss=\"alert\" aria-label=\"Close\">\n" +
        "    <span aria-hidden=\"true\">×</span>\n" +
        "  </button>\n" +
        "</div>";
    $('.success-handling').append(successHtml);
}

export function serverErrorHandle() {
    let errorHtml = "<div class=\"alert alert-danger alert-dismissible fade show\" role=\"alert\">\n" +
        "          <strong>Server Error!</strong> Please contact super admin for more information.\n" +
        "          <button class=\"close\" type=\"button\" data-dismiss=\"alert\" aria-label=\"Close\">\n" +
        "            <span aria-hidden=\"true\">×</span>\n" +
        "          </button>\n" +
        "        </div>";
    $('.message-handling').append(errorHtml);
}
