$(document).on('turbolinks:load', function () {

    function readURL(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();

            reader.onload = function (e) {
                $('#category_icon_preview').attr('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]);
            console.log('change')
        }
    }

    $("#category_icon").change(function(){
        readURL(this);
    });
});