// import * as Dropzone from 'dropzone/dist/dropzone';
import Dropzone from "dropzone/dist/dropzone-amd-module";
Dropzone.autoDiscover = false;

$(document).on('turbolinks:load', function () {
    const FLASH_JOB_SERVER_URL = process.env.FLASH_JOB_SERVER_URL;
    const UPDATE_ATTACHMENT_URL = FLASH_JOB_SERVER_URL + '/v1/api/attachments';
    const UPDATE_JOB_URL = FLASH_JOB_SERVER_URL + '/admins/jobs/'
    const CREATE_JOB_URL = FLASH_JOB_SERVER_URL + '/admins/jobs'
    const SOCIAL = 'social';
    const SEARCH_TAG_URL= '/admins/tags'

    flatpickr('#datePickerStart', {
        enableTime: true,
        dateFormat: "Y-m-d H:i:S",
        defaultDate: new Date($("#datePickerStart").val())
    });
    flatpickr('#datePickerEnd', {
        enableTime: true,
        dateFormat: "Y-m-d H:i:S",
        defaultDate: new Date($("#datePickerEnd").val())
    });
    flatpickr('#datePickerRegistrationDeadline', {
        enableTime: true,
        dateFormat: "Y-m-d H:i:S",
        defaultDate: new Date($("#datePickerRegistrationDeadline").val())
    });
    if ($('#upload-file-job').length) {
        const maxFileSize = 10;
        let id_removed = [];
        let count_file = $('#count_file').val();
        let maxFile = $('#max_file').val();
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        let dropZone = new Dropzone("#upload-file-job", {
            init: function () {
                this.on("thumbnail", function(file, dataUrl) {
                    $('.dz-image').last().find('img').attr({width: '100%', height: '100%'});
                });
                let myDropzone = this;
                myDropzone.on('addedfile', function (file) {
                    if (this.files.length > maxFile) {
                        this.removeFile(this.files[maxFile]);
                        alert('Sorry, Limit ' + maxFile + ' file');
                    }

                    if (this.files.length) {
                        let _i, _len;
                        for (_i = 0, _len = this.files.length - 1; _i < _len; _i++) {
                            if (this.files[_i].name === file.name && this.files[_i].size === file.size) {
                                this.removeFile(file);
                                alert("duplicate: " + file.name);
                            }
                        }
                    }
                });
                this.on("addedfile", function (file) {
                    let removeButton = Dropzone.createElement("<button class='btn btn-sm btn-primary mt-2' align='center'>Remove</button>");
                    let _this = this;
                    removeButton.addEventListener("click", function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        _this.removeFile(file);
                    });
                    file.previewElement.appendChild(removeButton);
                });

                this.on("removedfile", function (file) {
                    id_removed.push(file.id);
                });

                this.on("error", function (file, message) {
                    alert(message);
                    this.removeFile(file);
                });

                if (typeof ($('#file_0').data("name")) != 'undefined') {
                    for (let i = 0; i < count_file; i++) {
                        let file = $("#file_" + i);
                        let file_info = {
                            id: file.data("id"),
                            name: file.data("name"),
                            size: file.data("size"),
                            url: file.data("url")
                        };
                        myDropzone.emit("addedfile", file_info);
                       if (validImageTypes.includes(file.data("type")) == true){
                           myDropzone.emit("thumbnail", file_info, file.data("url"));
                       }
                        myDropzone.emit("complete", file_info);
                        myDropzone.files.push(file_info);
                    }
                }
            },
            maxFiles: maxFile,
            maxFilesize: maxFileSize, // 10MB
            paramName: "file",
            url: UPDATE_ATTACHMENT_URL
        });

        $('.update-job').click(function () {
            validate_job();
            $('html, body').animate({
                scrollTop: $(".app-body").offset().top
            }, 1000);
            if (validate_job() == true){
                let html = jQuery('html');
                html.css('overflow', 'hidden');
                $("#wait").css("display", "block");
                $('.update-job').prop('disabled', true);
                let form = $('#form-job');
                let job_id = $('#job_id').val();
                let onCreate = !job_id
                let formdata = false;
                if (window.FormData){
                    formdata = new FormData(form[0]);
                }
                let formAction = form.attr('action');
                let files = dropZone.getAcceptedFiles();
                if (files.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        formdata.append('file_'+ i, files[i]);
                    }
                }
                formdata.append('file_id', id_removed);
                formdata.append('file_insert', files.length);

                // Append skill_id
                formdata.delete("job[skill_id]")
                let skill_id = $(`.select_skill_id[category_id="${$("#category").val()}"]`).val()
                formdata.append("job[skill_id][]", parseInt(skill_id)) 

                // Append tags
                getTags().forEach(tagName => {
                    formdata.append("job[tags][]", tagName)
                });

                // Append category_id
                formdata.delete("job[category_id]")
                formdata.append('job[category_id]', ($('#category').hasClass('d-none') ? parseInt($('#related-socials-category').val()) : parseInt($('#category').val())));

                // Append follower if type is social
                if($('#related-jobs_filter_follower').hasClass('d-none')){
                    formdata.delete("jobs[filter][follower]")
                }

                $.ajax({
                    url         : (onCreate ? CREATE_JOB_URL : UPDATE_JOB_URL + job_id) ,
                    data        : formdata ? formdata : form.serialize(),
                    cache       : false,
                    contentType : false,
                    processData : false,
                    type        : (onCreate ? 'POST' : 'PUT'),
                    success     : function(data, textStatus, jqXHR){
                        if (data.status) {
                            $("#wait").css("display", "none");
                            location.reload();
                            messageHandle.successHandle();
                        } else {
                            messageHandle.serverErrorHandle();
                        }
                    }
                });
            }
        });
    }

    function validate_job() {
        let title = $.trim($('#title').val());
        let description = $.trim($('#description').val());
        let budget = $.trim($('#budget').val());
        let datePickerStart= new Date($('#datePickerStart').val());
        let datePickerEnd  = new Date($('#datePickerEnd').val());

        let flag = true;
        if (title.length <= 0){

            $('#title_error').text('Please enter title!');
            flag = false;
        }
        else{
            $('#title_error').text('');
        }

        if (description.length <= 0){
            $('#description_error').text('Please enter description!');
            flag = false;
        }
        else{
            $('#description_error').text('');
        }

        if (budget.length <= 0){
            $('#budget_error').text('Please enter budget!');
            flag = false;
        }
        else{
            $('#budget_error').text('');
        }

        if (datePickerEnd < datePickerStart && $("#job_type").val() != "social"){
            $('#picker_end_error').text('Please choose date end < date start!');
            flag = false;
        } else {
            $('#picker_start_error').text('');
        }

        if (!$.isNumeric(budget)) {
            $('#budget_error').text('Please enter number!');
            flag = false;
        }

        if($("#client_id").length > 0 && !$("#client_id").val()){
            $('#client_id_error').text('Please enter Client_id!');
            flag = false
        }
        else {
            $('#client_id_error').text('')
        }
        return flag;
    }

    let job_type =  $('#job_type').val();

    check_type_job(job_type);

    $('#job_type').change(function () {
        check_type_job($(this).val());
    });

    function check_type_job(type) {
        if (type == 'onsite'){
            $('.job_address').css( "display", "block" );
            $('#address').prop('disabled', false);
        }else if (type == 'online'){
            $('.job_address').css( "display", "none" );
            $('#address').prop('disabled', true);
        }
    }

    $("#category, #job_type").change(function (e) { 
        e.preventDefault();
        check_select_skill_id()    
    });

    check_select_skill_id()

    function check_select_skill_id() {
        let select_skill_id = $(".select_skill_id")
        if(select_skill_id.length == 0) return;
        
        if($("#job_type").val() != SOCIAL){        
            $("#related-skill").removeClass("d-none")
            $("#related-social").addClass("d-none")

            $("#category").removeClass('d-none')
            $("#related-socials-category").addClass("d-none")
            let category_value = $("#category").val()
            select_skill_id.each(function () {
                if(parseInt($(this).attr("category_id")) == parseInt(category_value)){
                    $(this).removeClass("d-none")
                }
                else{
                    $(this).addClass("d-none")
                }
            });
            $('#related-jobs_filter_follower').addClass('d-none')
        }
        else {
            select_skill_id.addClass("d-none"); // hide select skill
            $("#related-skill").addClass("d-none")
            $("#related-social").removeClass("d-none")

            $("#category").addClass('d-none')
            $("#related-socials-category").removeClass("d-none")

            $('#related-jobs_filter_follower').removeClass('d-none')
        }
    }

    $("#hashtag-input").on("keyup", function(){
        $.ajax({
            url         : `${SEARCH_TAG_URL}?name=${$(this).val()}`,
            cache       : false,
            contentType : false,
            processData : false,
            type        : 'GET',
            success     : function(data, textStatus, jqXHR){
                if (data.status) {
                    $("#related-select-tag").html('')
                    data.data.forEach(function (item) {  
                        $("#related-select-tag").append(`<li class="list-group-item tag list-group-item-action">${item.name}</li>`)
                    })

                    $("#related-select-tag .tag").click(function () {
                        addTag($(this).text())
                    })
                } else {
                    alert("Errors")
                }
            }
        });
    })

    $("#btn-add-tag").click(function () {  
        let text =  $("#hashtag-input").val()
        if(!text) return

        addTag(text)
    })

    function addTag(text) {  
        let add = true
        for (let i = 0; i < $("#related-tag .hashtag").length; i++) {
            const element = $("#related-tag .hashtag")[i];
            if($(element).text().trim() == text.trim()){
                add = false
                break
            }
        }
        if(add == false) return;
        let newTag = $(`<div class="hashtag mr-2 mt-1 mb-1">${text.trim()}</div>`)            
        $("#related-tag").append(newTag)
        
        addEventRemoveTag(newTag)
    }

    addEventRemoveTag()
    function addEventRemoveTag(element = null) {  
        $(element ? element : ".hashtag").click(function () {  
            $(this).remove()
        })
    }

    function getTags() {  
        let result = []
        $("#related-tag .hashtag").each(function (index, tag) {  
            result.push($(tag).text().trim())
        })
        return result
    }
    
    if(window.location.pathname == "/admins/jobs/new"){
        for (let i = 1; i <= 6; i++) {
            let checkAge = `#checkbox${i}`
            $(checkAge).attr("checked", "checked")
        }
    }
});
