$(document).on('turbolinks:load', function () {
    $(".page-link").attr('data-turbolinks', false);
    flatpickr('#rangeDatePicker', {
        mode: "range",
        dateFormat: "Y-m-d"
    });

    $('#btn-add-point').click(function (e) {
        e.preventDefault();
        $('#addPointForm').trigger("reset");
        resetText();
        $('.message-handling').html('');
    });

    $('#addPointForm')
        .on('ajax:success', function (data) {
            const dataReturn = data.detail[0];
            if (dataReturn.status) {
                $('#addPointModal').modal('hide');
                $('#alert-add-point-success').removeClass('d-none');
            } else {
                resetText();
                let errorMessage = dataReturn.error.error_messages[0];
                setErrorText($('#error-reason'), errorMessage);
            }
        })
        .on('ajax:error', function () {
            messageHandle.serverErrorHandle();
        });

    function setErrorText(input, message) {
        input.html(message)
    }

    function resetText() {
        $('.input-error').html('');
        $('#add-point-success').text('');
    }
});
