$(document).on('turbolinks:load', function () {
    const urlUpdatePermission = '/admins/permissions/synchronize_permission';
    $('.admin-permission').change(function () {
        let admin_id = $(this).data('admin-id');
        let object = $(this).data('object');
        let can = $(this).val();
        let data = {admin_id: admin_id, object: object, can: can};
        $.ajax({
            type: "POST",
            url: urlUpdatePermission,
            data: data,
            success: function (res) {
                if(res.status){
                    messageHandle.successHandle()
                }else{
                    messageHandle.serverErrorHandle();
                }
            },
            error: function () {
                messageHandle.serverErrorHandle();
            }
        });
    })
});
