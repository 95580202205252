$(document).on('turbolinks:load', function () {
    $("img").click(function(){
        const imgUrl = $(this).attr('src');
        $("#showImage").attr('src', imgUrl);
    });
    // in unchecked list
    $(".unchecked-action")
        .on("ajax:success", function (event) {
            const res = event.detail[0];
            if (res.status) {
                event.currentTarget.closest("tr").remove();
            }
        });

    // in checked list
    $(".reject-checked")
        .on("ajax:success", function (event) {
            const res = event.detail[0];
            if (res.status) {
                changeStatusToReject($(event.currentTarget))
            }
        });

    $(".approve-checked")
        .on("ajax:success", function (event) {
            const res = event.detail[0];
            if (res.status) {
                changeStatusToApproved($(event.currentTarget))
            }
        });

    function changeStatusToReject(actionClickElement) {
        let statusElement = actionClickElement.closest("tr").find(".badge");
        let approvedElement = actionClickElement.closest("td").find(".approve-checked");
        //status Element process
        statusElement.removeClass('badge-danger badge-success');
        statusElement.addClass('badge-danger');
        statusElement.text('No');
        // action Element process
        actionClickElement.addClass('d-none');
        approvedElement.removeClass('d-none');
    }

    function changeStatusToApproved(actionClickElement) {
        let statusElement = actionClickElement.closest("tr").find(".badge");
        let rejectElement = actionClickElement.closest("td").find(".reject-checked");
        //status Element process
        statusElement.removeClass('badge-danger badge-success');
        statusElement.addClass('badge-success');
        statusElement.text('Yes');
        // action Element process
        actionClickElement.addClass('d-none');
        rejectElement.removeClass('d-none');
    }
});
