import firebase from './firebase_initialize';
import 'firebase/storage';

const storage = firebase.storage();
const storageRef = storage.ref();
const ref = process.env.FB_STORAGE_NAME;

function deleteStorage(id, fileName) {
    return storageRef.child(`${ref}/${id}/${fileName}`).delete();
}

function uploadStorage(id, fileName, file) {
    return storageRef.child(`${ref}/${id}/${fileName}`).put(file);
}

function getDownloadUrl(id, fileName) {
    return storageRef.child(`${ref}/${id}/${fileName}`).getDownloadURL();
}

export {deleteStorage, uploadStorage, getDownloadUrl}