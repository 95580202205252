$(document).on('turbolinks:load', function () {
    /***** local storage variable:
     * check-list-${currentPage}: list of checkboxes(ids) that are checked in currentPage(pagy)
     * totalCheckedIds: List of checkboxes(ids) in all pagies
    *****/

    /*************** List event ***************/

    let adminListJobTable = $('.list-check-table');
    if(adminListJobTable.length > 0) {
        displayCurrentCheckList();
        showCountTotalChecked();
    }
    else {
        localStorage.clear();
    }

    $('#check-all').click(function (e) {
        let checkAllStatus = $(this).prop('checked');
        $('.check-item').prop('checked', checkAllStatus);
    });

    $('.check-item').click(function (e) {
        setCheckAll();
    });

    $('.list-check-table input:checkbox').click(function (e) {
        saveCurrentCheckList();
        setTotalCheckedIds();
        showCountTotalChecked();
    });

    //Get all checked ids and add to an input value
    $('#update-checked-ids').click(function (e) {
        let totalCheckedIds = localStorage.getItem('totalCheckedIds');
        $('#checked_ids').val(totalCheckedIds);
        localStorage.clear();
    });

    //For showing modal
    $('#jobs_status').change(function (e) {
        $('#confirm-change-status').modal('show');
        let jobId = $(this).data('id');
        let changeStatusUrl = `/admins/jobs/${jobId}/update_status`;
        let statusParams = `?status=${$(this).val()}`;
        $('#btn-update-status').attr('href', changeStatusUrl + statusParams);
    });

    /*************** List function ***************/

    //Count checkboxes

    function countChecked() {
        return $('.check-item:checked').length;
    }

    function countUnchecked() {
        return $('.check-item:not(:checked)').length;
    }

    //Get checked and unchecked Ids

    function currentCheckedIds() {
        let currentPageCheckedItems = [];
        $('.check-item:checked').each(function (e) {
            currentPageCheckedItems.push($(this).val());
        });
        return currentPageCheckedItems;
    }

    function currentUnCheckedIds() {
        let currentPageUnCheckedItems = [];
        $('.check-item:not(:checked)').each(function (e) {
            currentPageUnCheckedItems.push($(this).val());
        });
        return currentPageUnCheckedItems;
    }

    //Set value of check-all checkbox

    function setCheckAll() {
        if(countUnchecked() == 0) {
            $('#check-all').prop('checked', true);
        }
        else {
            $('#check-all').prop('checked', false);
        }
    }

    //Show number of all checked ids with element
    function showCountTotalChecked() {
        let totalCheckedIds = localStorage.getItem('totalCheckedIds');
        totalCheckedIds = totalCheckedIds == null || totalCheckedIds == "" ? [] : totalCheckedIds.split(',');
        let countTotalChecked = totalCheckedIds.length;
        if(countTotalChecked > 0) {
            $('#count-total-checked').text(countTotalChecked);
            $('.text-count-checked').removeClass('d-none');
        }
        else {
            $('.text-count-checked').addClass('d-none');
        }
    }

    function saveCurrentCheckList() {
        let currentPage = $('.page-item.active .page-link').text();
        localStorage.setItem(`check-list-${currentPage}`, currentCheckedIds())
    }

    function displayCurrentCheckList() {
        let currentPage = $('.page-item.active .page-link').text();
        let currentPageCheckItems = localStorage.getItem(`check-list-${currentPage}`);
        if(currentPageCheckItems != null) {
            let checkList = currentPageCheckItems.split(',');
            $('.check-item').each(function (e) {
                if(checkList.indexOf($(this).val()) > -1) {
                    $(this).prop('checked', true);
                }
            });
            setCheckAll();
        }
    }

    function setTotalCheckedIds() {
        let totalCheckedIds = localStorage.getItem('totalCheckedIds');
        // Get array of checked ids
        totalCheckedIds = totalCheckedIds == null || totalCheckedIds == "" ? [] : totalCheckedIds.split(',');
        // Subtract unchecked ids
        totalCheckedIds = totalCheckedIds.filter(id => currentUnCheckedIds().indexOf(id) == -1);
        // Add new checked ids
        totalCheckedIds = totalCheckedIds.concat(currentCheckedIds());
        // Get new array of checked ids (remove duplicates)
        totalCheckedIds = Array.from(new Set(totalCheckedIds));
        localStorage.setItem('totalCheckedIds', totalCheckedIds);
    }
});
