// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require.context('../images', true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import '../stylesheets/application';
import '@fortawesome/fontawesome-free/js/all';
import '@coreui/coreui/dist/js/coreui';
import 'perfect-scrollbar/dist/perfect-scrollbar';
import 'bootstrap';
import '@dashboardcode/bsmultiselect';
import Flatpickr from 'flatpickr';
import * as messageHandle from 'js/message_handle';
import 'js/clients';
import 'js/admins';
import 'js/jobs';
import 'js/permissions';
import 'js/messages';
import 'js/common';
import 'js/admin_list_jobs';
import 'js/firebase_push_schedules';
import 'js/block_ips';
import 'js/categories';
import 'js/coupon';
import 'js/id_card.js';
window.jQuery = $;
window.$ = $;
window.messageHandle = messageHandle;
